const axios = require('axios');

class AuthApi {
  async login({ email, password }) {
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/login`;
    const resp = await axios.post(
      apiPath,
      {
        email,
        password,
      },
      axiosConfig
    );
    if (resp.status !== 200) {
      throw resp.data;
    } else {
      return resp.data;
    }
  }

  async me(accessToken) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/get-admin-user`;
    const resp = await axios.get(apiPath, axiosConfig);
    if (resp.status !== 200) {
      throw resp.data;
    } else {
      const user = resp.data;
      return {
        id: user.id,
        avatar: null,
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        user_role: user.user_role,
        roles: user.roles,
        name: `${user?.firstname?.toLowerCase()} ${user?.lastname?.toLowerCase()}`,
        is_async: user.is_async,
      };
    }
  }

  async logout(accessToken) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/logout`;
    await axios.get(apiPath, axiosConfig);
  }
}

export const authApi = new AuthApi();
