import React, { useState } from 'react';
import {
  IconButton,
  // makeStyles,
} from '@material-ui/core';
import Menu from '../../icons/Menu';
import DashboardSidebar from './DashboardSidebar';
import { useLocation } from 'react-router-dom';

// const useStyles = makeStyles(() => ({
//   link: {
//     textDecoration: 'none',
//     color: 'blue',
//     fontSize: '20px',
//   },
//   icon: {
//     color: 'white',
//   },
// }));

function DrawerComponent() {
  // const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  //
  // const [anchorEl, setAnchorEl] = useState(false);
  // const [ehrPateintDrop, setEhrPateintDrop] = useState(false);
  // const [walnutDrop, setWalnutDrop] = useState(false);
  // const [nmiDrop, setNmiDrop] = useState(false);
  // const [squareupDrop, setSquareupDrop] = useState(false);
  // const [stripeDrop, setStripeDrop] = useState(false);

  // const open = Boolean(anchorEl);
  // const openEhr = Boolean(ehrPateintDrop);
  // const openWainut = Boolean(walnutDrop);
  // const openNmi = Boolean(nmiDrop);
  // const openSquareupDrop = Boolean(squareupDrop);
  // const openStripeDrop = Boolean(stripeDrop);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  //
  // const handleClickEhr = (event) => {
  //   setEhrPateintDrop(event.currentTarget);
  // };
  //
  // const handleClickWalnut = (event) => {
  //   setWalnutDrop(event.currentTarget);
  // };
  // const handleClickNmi = (event) => {
  //   setNmiDrop(event.currentTarget);
  // };
  // const handleClickSquareupDrop = (event) => {
  //   setSquareupDrop(event.currentTarget);
  // };
  // const handleClickStripeDrop = (event) => {
  //   setStripeDrop(event.currentTarget);
  // };
  //
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  //
  // const handleCloseEhr = () => {
  //   setEhrPateintDrop(null);
  // };
  //
  // const handleCloseWalnut = () => {
  //   setWalnutDrop(null);
  // };
  // const handleCloseNmi = () => {
  //   setNmiDrop(null);
  // };
  // const handleCloseSquareupDrop = () => {
  //   setSquareupDrop(null);
  // };
  // const handleCloseStripeDrop = () => {
  //   setStripeDrop(null);
  // };

  const location = useLocation();

  React.useEffect(() => {
    setOpenDrawer(false);
  }, [location.pathname]);

  return (
    <>
      {openDrawer && <DashboardSidebar />}
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <Menu sx={{ color: 'white' }} />
      </IconButton>
    </>
  );
}
export default DrawerComponent;
