import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useState, useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import { customerApi } from '../../../api/customerApi';
import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Backdrop,
  CircularProgress,
  Typography,
  Breadcrumbs,
  Link,
  Container,
  MenuItem
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useSettings from '../../../hooks/useSettings';
import useMounted from '../../../hooks/useMounted';

const insuranceCompanies = [
  {
    value: 'blueCrossBlueShieldOfTexas',
    label: 'Blue Cross Blue Shield of Texas'
  },
  {
    value: 'unitedHealthcare',
    label: 'United Healthcare'
  },
  {
    value: 'aetna',
    label: 'Aetna'
  },
  {
    value: 'cignaEvernorthBehavioralHealth',
    label: 'Cigna Evernorth Behavioral Health'
  },
  {
    value: 'anthemBCBS',
    label: 'Anthem BCBS'
  },
  {
    value: 'other',
    label: 'Other'
  },
];

const InsuranceDetailsEditForm = (props) => {
  const { insuranceDetails, ...other } = props;
  const [open, setOpen] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const { customerId } = useParams();
  const { settings } = useSettings();
  const mounted = useMounted();
  const [customer, setCustomer] = useState(null);
  const { insurance_company, insurance_member_id } = customer || {};

  const getCustomer = useCallback(async () => {
    try {
      const data = await customerApi.getCustomer(window.localStorage.getItem('accessToken'), customerId);

      if (mounted.current) {
        setCustomer(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  if (!customer) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 8
      }}
    >
      <Container maxWidth={settings.compact ? 'xl' : false}>
        <Formik
          initialValues={{
            submit: null,
            insurance_company: insurance_company || '',
            insurance_member_id: insurance_member_id || '',
            other_insurance_company: ''
          }}
          enableReinitialize
          validationSchema={Yup.object().shape({
            insurance_company: Yup.string().required('Insurance company is required'),
            insurance_member_id: Yup.string().required('Insurance Member ID is required'),
            other_insurance_company: Yup.string().when('insurance_company', {
              is: 'other',
              then: Yup.string().required('Please specify the insurance company')
            })
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              setOpen(true);
              const payload = {
                insurance_company: values.insurance_company === 'other' ? values.other_insurance_company : values.insurance_company,
                insurance_member_id: values.insurance_member_id
              };
              await customerApi.updateCustomer(window.localStorage.getItem('accessToken'), customerId, payload);
              await getCustomer();
              setStatus({ success: true });
              setSubmitting(false);
              toast.success('Insurance details updated!');
              setOpen(false);
            } catch (err) {
              console.error(err);
              toast.error('Something went wrong!');
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
              setOpen(false);
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} {...other}>
              <Card>
                <Box sx={{ p: 3 }}>
                  <Backdrop sx={{ color: '#5271ff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  <Grid item sx={{ mb: 3 }}>
                    <Typography
                      color="textPrimary"
                      variant="h5"
                    >
                      Insurance Details Edit
                    </Typography>
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      separator={<ChevronRightIcon fontSize="small" />}
                      sx={{ mt: 1 }}
                    >
                      <Link
                        color="textPrimary"
                        component={RouterLink}
                        to="/dashboard"
                        variant="subtitle2"
                      >
                        Dashboard Home
                      </Link>
                      <Link
                        color="textPrimary"
                        component={RouterLink}
                        to={`/dashboard/customers/${customerId}`}
                        variant="subtitle2"
                      >
                        Customer Details
                      </Link>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        Edit Insurance Details
                      </Typography>
                    </Breadcrumbs>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        select
                        fullWidth
                        label="Insurance Company"
                        name="insurance_company"
                        value={values.insurance_company}
                        onChange={(e) => {
                          handleChange(e);
                          setShowOther(e.target.value === 'other');
                        }}
                        variant="outlined"
                        error={Boolean(touched.insurance_company && errors.insurance_company)}
                        helperText={touched.insurance_company && errors.insurance_company}
                        onBlur={handleBlur}
                      >
                        {insuranceCompanies.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {showOther && (
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Other Insurance Company"
                          name="other_insurance_company"
                          value={values.other_insurance_company}
                          onChange={handleChange}
                          variant="outlined"
                          error={Boolean(touched.other_insurance_company && errors.other_insurance_company)}
                          helperText={touched.other_insurance_company && errors.other_insurance_company}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Insurance Member ID"
                        name="insurance_member_id"
                        value={values.insurance_member_id}
                        onChange={handleChange}
                        variant="outlined"
                        error={Boolean(touched.insurance_member_id && errors.insurance_member_id)}
                        helperText={touched.insurance_member_id && errors.insurance_member_id}
                        onBlur={handleBlur}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 2 }}>
                    <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  );
};

InsuranceDetailsEditForm.propTypes = {
  insuranceDetails: PropTypes.object.isRequired
};

export default InsuranceDetailsEditForm;
