const axios = require('axios');

const defaultAxiosConfig = (accessToken) => ({
  headers: {
    Authorization: accessToken,
    'Content-Type': 'application/json;charset=UTF-8',
  },
});
class CustomerApi {
  async getCustomers(params = {}) {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    const queryParams = new URLSearchParams();
    // Add parameters to queryParams only if they are defined
    if (params.offset) queryParams.append('offset', params.offset);
    if (params.limit) queryParams.append('limit', params.limit);
    if (params.userStatus) queryParams.append('userStatus', params.userStatus);
    if (params.searchValue) queryParams.append('searchValue', params.searchValue);
    if (params.sortBy) queryParams.append('sortBy', params.sortBy);
    if (params.sortDir) queryParams.append('sortDir', params.sortDir);
    if (params.filterBySaleRepId) queryParams.append('filterBySaleRepId', params.filterBySaleRepId);
    if (params.userSubStatus) queryParams.append('userSubStatus', params.userSubStatus);
    if (params.ketaminePack) queryParams.append('ketaminePack', params.ketaminePack);
    if (params.genPsych) queryParams.append('genPsych', params.genPsych);
    if (params.traumaPack) queryParams.append('traumaPack', params.traumaPack);
    if (params.naltrexonePack) queryParams.append('naltrexonePack', params.naltrexonePack);
    if (params.insuranceCustomer) queryParams.append('insuranceCustomer', params.insuranceCustomer);
    console.log('queryParams', queryParams.toString());
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/listV2?${queryParams.toString()}`;
    const resp = await axios.get(apiPath, axiosConfig);
    return resp.data;
  }

  async getCustomerNotifications(accessToken, customerId) {
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/notifications/user/${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerFormSubmissions(customerId) {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/forms?userId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerSurvey(customerId) {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/surveys/list?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig,
    );
    return resp.data;
  }

  async getInsuranceCollectionsList(daysToAppointment = 20) {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/insurance/collections?daysToAppointment=${daysToAppointment}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig,
    );
    return resp.data;
  }

  async getSubscriptionRefillsList() {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/subscriptions/refill-dates`;
    const resp = await axios.get(
      apiPath,
      axiosConfig,
    );
    return resp.data;
  }

  async updateNaltrexoneRefill(data) {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/subscriptions/refill-updates`;
    const resp = await axios.put(
      apiPath,
      JSON.stringify(data),
      axiosConfig,
    );
    return resp.data;
  }

  async getSalesRepresentative() {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/sales-perosnal/list`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async fetchCustomerByStatus(params = {}) {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/list-by-status`;

    // Ensure status is always present in the params
    if (!params.status) {
      params.status = 'lead'; // Default status if not provided
    }

    // Convert boolean values to strings
    const preparedParams = Object.entries(params).reduce((acc, [key, value]) => {
      acc[key] = typeof value === 'boolean' ? value.toString() : value;
      return acc;
    }, {});

    try {
      const resp = await axios.post(apiPath, preparedParams, axiosConfig);
      return resp.data;
    } catch (error) {
      console.error('Error fetching customers by status:', error);
      throw error;
    }
  }

  async updateCustomerStatus(customerId, status, reason) {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);
    const resp = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/admin/customers/change-status`,
      {
        user_status: status,
        user_status_reason: reason,
        id: customerId
      },
      axiosConfig
    );
    return resp.data;
  }

  async getBIOrders(accessToken) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/bi/orders`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getBIFacebook(accessToken) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/bi/facebook`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomer(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getTodos(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/todos-v3?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async updateTodos(accessToken, customerId, todos) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/todos?customerId=${customerId}`;
    const resp = await axios.post(
      apiPath,
      todos,
      axiosConfig
    );
    return resp.data;
  }

  async getPurchases(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/purchases?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getAllPurchases(params = {}) {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);

    // Build query string based on params
    const queryParams = new URLSearchParams();

    // Append only the params that are passed in
    if (params.customerId) queryParams.append('customerId', params.customerId);
    if (params.offset) queryParams.append('offset', params.offset);
    if (params.limit) queryParams.append('limit', params.limit);
    if (params.sortBy) queryParams.append('sortBy', params.sortBy);
    if (params.sortDir) queryParams.append('sortDir', params.sortDir);
    if (params.productType) queryParams.append('productType', params.productType);
    if (params.isInsurancePurchase !== null && params.isInsurancePurchase !== undefined) {
      queryParams.append('isInsurancePurchase', params.isInsurancePurchase.toString());
    }
    if (params.statusReason) queryParams.append('statusReason', params.statusReason);
    if (params.purchaseStatus) queryParams.append('purchaseStatus', params.purchaseStatus);
    if (params.userStatus) queryParams.append('userStatus', params.userStatus);
    if (params.searchValue) queryParams.append('searchValue', params.searchValue);
    if (params.userState) queryParams.append('userState', params.userState);
    if (params.isFirstPurchase !== null && params.isFirstPurchase !== undefined) {
      queryParams.append('isFirstPurchase', params.isFirstPurchase.toString());
    }
    if (params.showTestUsers !== null && params.showTestUsers !== undefined) {
      queryParams.append('showTestUsers', params.showTestUsers.toString());
    }

    // Construct the API path
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/purchases/list?${queryParams.toString()}`;

    try {
      // Make the API request
      const response = await axios.get(apiPath, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching purchases:', error);
      throw error;
    }
  }

  async getAllLeads(params = {}) {
    const accessToken = window.localStorage.getItem('accessToken');
    const axiosConfig = defaultAxiosConfig(accessToken);

    // Build query string based on params
    const queryParams = new URLSearchParams();

    // Append only the params that are passed in
    if (params.offset) queryParams.append('offset', params.offset);
    if (params.limit) queryParams.append('limit', params.limit);
    if (params.sortBy) queryParams.append('sortBy', params.sortBy);
    if (params.sortDir) queryParams.append('sortDir', params.sortDir);
    if (params.productType) queryParams.append('productType', params.productType);
    if (params.formSource) queryParams.append('formSource', params.formSource);
    if (params.hasPassedLastAssessment) queryParams.append('hasPassedLastAssessment', params.hasPassedLastAssessment);
    if (params.state) queryParams.append('state', params.state);
    if (params.salesOwner) queryParams.append('salesOwner', params.salesOwner);
    if (params.hasInsuranceDetails !== null && params.hasInsuranceDetails !== undefined) {
      queryParams.append('hasInsurance', params.hasInsuranceDetails.toString());
    }
    if (params.searchValue) queryParams.append('search', params.searchValue);

    // Construct the API path
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/leads?${queryParams.toString()}`;

    try {
      // Make the API request
      const response = await axios.get(apiPath, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching leads:', error);
      throw error;
    }
  }

  async updatePurchase(accessToken, purchaseId, update) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/purchases?purchaseId=${purchaseId}`;
    const resp = await axios.post(
      apiPath,
      update,
      axiosConfig
    );
    return resp.data;
  }

  async updatePackComplete(accessToken, purchaseId, update) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/purchases/complete?purchaseId=${purchaseId}`;
    const resp = await axios.post(
      apiPath,
      update,
      axiosConfig
    );
    return resp.data;
  }

  async getPrescriptionTracking(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/prescription-tracking?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerPaymentsSqaureup(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customer-payments-sqaureup?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerSubscriptionsSqaureup(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customer-subscriptions-sqaureup?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerSubscriptionsCancelSqaureup(accessToken, subId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customer-subscriptions-cancel-sqaureup?subId=${subId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async refundCustomerSquareup(accessToken, customerId, paymentId, amount) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customer-refunds-sqaureup?customerId=${customerId}&paymentId=${paymentId}&amount=${amount}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async updateCustomer(accessToken, customerId, data) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers?customerId=${customerId}`;
    const resp = await axios.put(
      apiPath,
      data,
      axiosConfig
    );
    return resp.data;
  }

  async updateInsuranceEligibilityDetails(accessToken, customerId, data) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/insurance?userId=${customerId}`;
    const resp = await axios.put(
      apiPath,
      data,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerPayments(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/payments?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerSubscriptions(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/subscriptions?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async cancelCustomerSubscription(accessToken, subId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/subscriptions/cancel?id=${subId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerTrackings(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/tracking?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomersDocumentsList(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/documents?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerFormsList(accessToken, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/medical-intake-forms/list?customerId=${customerId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getCustomerFormDetails(accessToken, medicalIntakeId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/medical-intake-form-by-id?medicalIntakeId=${medicalIntakeId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async uploadCustomersDocument(accessToken, customerId, data) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/documents?customerId=${customerId}`;
    const resp = await axios.post(
      apiPath,
      data,
      axiosConfig
    );
    return resp.data;
  }

  async getS3SignedDocumentLink(accessToken, documentId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/documents/signed-url?documentId=${documentId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getAppointmentLogs(accessToken, customerId, purchaseId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/appointment-logs?customerId=${customerId}&purchaseId=${purchaseId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async deleteAppointmentLogs(accessToken, customerId, logId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/appointment-logs?customerId=${customerId}&logId=${logId}`;
    const resp = await axios.delete(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async createAppointmentLogs(accessToken, log) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/appointment-logs`;
    const resp = await axios.post(
      apiPath,
      log,
      axiosConfig
    );
    return resp.data;
  }

  async refundCustomer(accessToken, chargeId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/payments/refund?id=${chargeId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async refundCustomerWalnut(accessToken, id) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/payments/walnut-refund?id=${id}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async getAllCustomers(accessToken) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/list?all=true`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async createHsaDenefitsPurchase(accessToken, customerId, data) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/create-hsa-purchase?customerId=${customerId}`;
    const resp = await axios.post(
      apiPath,
      data,
      axiosConfig
    );
    return resp.data;
  }

  async getAsyncConsults(accessToken, params = {}) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    let apiPath = `${process.env.REACT_APP_BACKEND_URL}/async-consultation`;
    const urlParams = new URLSearchParams();
    Object.keys(params).forEach((key) => {
      if (params[key]) urlParams.append(key, params[key]);
    });
    if (urlParams.toString()) {
      apiPath += `?${urlParams.toString()}`;
    }
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async finalizeAsyncConsult(accessToken, consultationId, status, outcome) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/async-consultation/${consultationId}/finalize`;
    const data = {
      status,
      outcome
    };
    const resp = await axios.post(
      apiPath,
      data,
      axiosConfig
    );
    return resp.data;
  }

  async getChat(accessToken, chatId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/chat/${chatId}`;
    const resp = await axios.get(
      apiPath,
      axiosConfig
    );
    return resp.data;
  }

  async sendMessage(accessToken, chatId, message, attachments = []) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/chat/${chatId}/message`;
    const data = {
      message,
      attachments
    };
    const resp = await axios.post(
      apiPath,
      data,
      axiosConfig
    );
    return resp.data;
  }

  async getFile(accessToken, chatId, fileId, customerId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/chat/${chatId}/user/${customerId}/file/${fileId}`;
    try {
      const resp = await axios.get(
        apiPath,
        axiosConfig
      );
      return resp.data;
    } catch (error) {
      console.error('Failed to get file', error);
      throw error;
    }
  }

  async getPrescreen(accessToken, patientId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/forms/${patientId}/prescreen`;
    try {
      const resp = await axios.get(
        apiPath,
        axiosConfig
      );
      return resp.data;
    } catch (error) {
      console.error('Failed to get prescreen', error);
      throw error;
    }
  }

  async getPatientPrescriptions(accessToken, patientId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/prescriptions/patient/${patientId}`;
    try {
      const resp = await axios.get(
        apiPath,
        axiosConfig
      );
      return resp.data;
    } catch (error) {
      console.error('Failed to get patient prescriptions', error);
      throw error;
    }
  }

  async getPatientPrescriptionsWithRefills(accessToken, patientId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/prescriptions/patient/${patientId}/with-refills`;
    try {
      const resp = await axios.get(
        apiPath,
        axiosConfig
      );
      return resp.data;
    } catch (error) {
      console.error('Failed to get patient prescriptions', error);
      throw error;
    }
  }

  async createPrescription(accessToken, prescriptionData) {
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/prescriptions`;
    try {
      const resp = await axios.post(apiPath, prescriptionData, axiosConfig);
      return resp.data;
    } catch (error) {
      console.error('Error creating prescription:', error);
      throw error;
    }
  }

  async createPrescriptionRefill(accessToken, prescriptionId) {
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/prescriptions/${prescriptionId}/refill`;
    try {
      const resp = await axios.post(apiPath, {}, axiosConfig);
      return resp.data;
    } catch (error) {
      console.error('Error creating prescription refill:', error);
      throw error;
    }
  }

  async cancelPrescription(accessToken, prescriptionId) {
    const axiosConfig = {
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/prescriptions/${prescriptionId}/cancel`;
    try {
      const resp = await axios.post(
        apiPath,
        {},
        axiosConfig
      );
      return resp.data;
    } catch (error) {
      console.error('Failed to cancel prescription', error);
      throw error;
    }
  }

  async getSalesNotes(accessToken, userId) {
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/users/${userId}/sales-notes`;
    try {
      const resp = await axios.get(apiPath, axiosConfig);
      return resp.data;
    } catch (error) {
      console.error('Error fetching sales notes:', error);
      throw error;
    }
  }

  async createSalesNote(accessToken, userId, note) {
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/users/sales-notes`;
    try {
      const resp = await axios.post(apiPath, {
        note,
        user_id: userId
      }, axiosConfig);
      return resp.data;
    } catch (error) {
      console.error('Error creating sales note:', error);
      throw error;
    }
  }

  async toggleUserSuppression(accessToken, userId) {
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/users/${userId}/toggle-suppression`;
    try {
      const resp = await axios.post(apiPath, {}, axiosConfig);
      return resp.data;
    } catch (error) {
      console.error('Error toggling user suppression:', error);
      throw error;
    }
  }

  async getPrescriptionPrefill(accessToken, purchaseId) {
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/prescriptions/${purchaseId}/prefill`;
    try {
      const resp = await axios.get(apiPath, axiosConfig);
      return resp.data;
    } catch (error) {
      console.error('Error getting prescription prefill:', error);
      throw error;
    }
  }

  async getPrescriptionDetails(accessToken, prescriptionId) {
    const axiosConfig = defaultAxiosConfig(accessToken);
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/prescriptions/${prescriptionId}`;
    try {
      const resp = await axios.get(apiPath, axiosConfig);
      return resp.data;
    } catch (error) {
      console.error('Error getting prescription details:', error);
      throw error;
    }
  }
}

export const customerApi = new CustomerApi();
