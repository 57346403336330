import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import ArrowRightIcon from '../../icons/ArrowRight';
import toast from 'react-hot-toast';
import {
  Button,
  Box
} from '@material-ui/core';

const customLogout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      toast.error('Unable to logout.');
    }
  };
  return (
    <Box>
      <Button
        color="primary"
        endIcon={<ArrowRightIcon />}
        size="small"
        onClick={handleLogout}
        variant="contained"
      >
        Logout
      </Button>
    </Box>
  );
};

export default customLogout;
