import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useState, useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import { customerApi } from '../../../api/customerApi';
import {
  Box,
  Button,
  Card,
  Grid,
  Switch,
  TextField,
  Backdrop,
  CircularProgress,
  FormControlLabel,
  Typography,
  Breadcrumbs,
  Link,
  Container
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useSettings from '../../../hooks/useSettings';
import useMounted from '../../../hooks/useMounted';

const InsuranceEligibilityEditForm = (props) => {
  const { insuranceDetails, ...other } = props;
  const [open, setOpen] = useState(false);
  const { customerId } = useParams();
  const { settings } = useSettings();
  const mounted = useMounted();
  const [customer, setCustomer] = useState(null);
  const { insurance_details } = customer || {};

  const getCustomer = useCallback(async () => {
    try {
      const data = await customerApi.getCustomer(window.localStorage.getItem('accessToken'), customerId);

      if (mounted.current) {
        setCustomer(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  if (!customer) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 8
      }}
    >
      <Container maxWidth={settings.compact ? 'xl' : false}>
        <Formik
          initialValues={{
            eligibility: insurance_details?.eligibility || false,
            in_network: insurance_details?.in_network || false,
            deductible: insurance_details?.deductible || '',
            copay: insurance_details?.copay || '',
            coinsurance_percentage: insurance_details?.coinsurance_percentage || '',
            coinsurance_amount: insurance_details?.coinsurance_amount || '',
            referral_authorization: insurance_details?.referral_authorization || '',
            additional_notes: insurance_details?.additional_notes || '',
            notified_of_eligibility: insurance_details?.notified_of_eligibility || false
          }}
          enableReinitialize
          validationSchema={Yup
            .object()
            .shape({
              deductible: Yup.number().typeError('Must be a number').required('Deductible is required'),
              copay: Yup.number().typeError('Must be a number').required('Copay is required'),
              coinsurance_percentage: Yup.number().typeError('Must be a percentage').required('Coinsurance (percentage) is required'),
              coinsurance_amount: Yup.number().typeError('Must be a number').required('Coinsurance (amount) is required'),
              referral_authorization: Yup.string().max(255).required('Referral/Authorization is required'),
              notified_of_eligibility: Yup.boolean().required('Patient notified of eligibility details is required'),
              additional_notes: Yup.string().max(1000)
            })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              setOpen(true);
              await customerApi.updateInsuranceEligibilityDetails(window.localStorage.getItem('accessToken'), customerId, values);
              await getCustomer();
              setStatus({ success: true });
              setSubmitting(false);
              toast.success('Insurance details updated!');
              setOpen(false);
            } catch (err) {
              console.error(err);
              toast.error('Something went wrong!');
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
              setOpen(false);
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} {...other}>
              <Card>
                <Box sx={{ p: 3 }}>
                  <Backdrop sx={{ color: '#5271ff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  <Grid item sx={{ mb: 3 }}>
                    <Typography
                      color="textPrimary"
                      variant="h5"
                    >
                      Insurance Elegibility Edit
                    </Typography>
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      separator={<ChevronRightIcon fontSize="small" />}
                      sx={{ mt: 1 }}
                    >
                      <Link
                        color="textPrimary"
                        component={RouterLink}
                        to="/dashboard"
                        variant="subtitle2"
                      >
                        Dashboard Home
                      </Link>
                      <Link
                        color="textPrimary"
                        component={RouterLink}
                        to={`/dashboard/customers/${customerId}`}
                        variant="subtitle2"
                      >
                        Customer Details
                      </Link>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        Edit Insurance Eligibility
                      </Typography>
                    </Breadcrumbs>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={values.eligibility}
                            color="primary"
                            edge="start"
                            name="eligibility"
                            onChange={handleChange}
                          />
                        )}
                        label="Eligibility"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={values.in_network}
                            color="primary"
                            edge="start"
                            name="in_network"
                            onChange={handleChange}
                          />
                        )}
                        label="In Network"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.deductible && errors.deductible)}
                        fullWidth
                        helperText={touched.deductible && errors.deductible}
                        label="Deductible"
                        name="deductible"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.deductible}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.copay && errors.copay)}
                        fullWidth
                        helperText={touched.copay && errors.copay}
                        label="Copay"
                        name="copay"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.copay}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.coinsurance_percentage && errors.coinsurance_percentage)}
                        fullWidth
                        helperText={touched.coinsurance_percentage && errors.coinsurance_percentage}
                        label="Coinsurance (%)"
                        name="coinsurance_percentage"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.coinsurance_percentage}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.coinsurance_amount && errors.coinsurance_amount)}
                        fullWidth
                        helperText={touched.coinsurance_amount && errors.coinsurance_amount}
                        label="Coinsurance ($)"
                        name="coinsurance_amount"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.coinsurance_amount}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.referral_authorization && errors.referral_authorization)}
                        fullWidth
                        helperText={touched.referral_authorization && errors.referral_authorization}
                        label="Referral / Authorization"
                        name="referral_authorization"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.referral_authorization}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={values.notified_of_eligibility}
                            color="primary"
                            edge="start"
                            name="notified_of_eligibility"
                            onChange={handleChange}
                          />
                        )}
                        label="Patient notified of eligibility details"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.additional_notes && errors.additional_notes)}
                        fullWidth
                        helperText={touched.additional_notes && errors.additional_notes}
                        label="Additional Notes"
                        name="additional_notes"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        multiline
                        rows={4}
                        value={values.additional_notes}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 2 }}>
                    <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  );
};

InsuranceEligibilityEditForm.propTypes = {
  insuranceDetails: PropTypes.object.isRequired
};

export default InsuranceEligibilityEditForm;
