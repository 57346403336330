import { SvgIcon } from '@material-ui/core';

import { ReactComponent as LogoRoot } from '../media/chooselife.svg';

const Logo = (props) => (
  <SvgIcon
    height="10"
    version="1.1"
    alignitems="center"
    justifycontent="center"
    viewBox="0 10 160 30"
    overflow="visible"
    width="10"
    {...props}
  >
    <g transform="translate(0,0)">
      <title>Choose Ketamine</title>
      <LogoRoot />
    </g>
  </SvgIcon>
);

export default Logo;
